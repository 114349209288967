 export enum QuestionAnswersList {
  Skip = 1,
  All = 2
} 


export enum CrudAction {
  Add = 1,
  Edit = 2,
  Delete = 3
}

