<div class="container headerHeight" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%"
  fxLayoutAlign="center"
  style='background: url("../../../../assets/images/footer/Header2.png"); background-size: cover; '>
  <div fxLayout  fxFlex="90" class="flex">
    <div>
      <div class="overlaytext">Custom Chemistry Diagnosis</div>
      <div class="overlaytextTagLine">Pipeline and Process Services</div>
    </div>
  </div>
  <div  fxLayout fxFlex="10" class="flex">
    <a matTooltip='Admin' matTooltipClass="custom-tooltip-primary" *ngIf="isAdmin()" mat-list-item
      routerLink='/site/ccd-admin'>
      <mat-icon class="hamburger">create</mat-icon>
    </a>
    <a matTooltip='Contact' matTooltipClass="custom-tooltip-primary" mat-list-item
      href='mailto:PPS_Tech_Sales@halliburton.com'>
      <mat-icon matTooltipClass="custom-tooltip-primary" class="hamburger">live_help</mat-icon>
    </a>
  </div>
</div>