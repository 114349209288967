import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { QuestionWithAnswerResponse } from './model/questionWithAnswerResponse';
import { NotificationService } from './services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CcdService } from './services/ccd.service';
import { GlobalConstants } from './constants/global-constants';
import { QuestionAnswersList } from './model/enumerations';
import { AppInsightsMonitoringService } from './services/logging.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
    isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  answerList: any = [];
  questionWithAnswerList: QuestionWithAnswerResponse[] = [];

  constructor(
      @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
      private notificationService: NotificationService
    , private ccdService: CcdService
    , private globalConstants: GlobalConstants
    ,private logger: AppInsightsMonitoringService
  ) { }

  ngOnInit(): void {
    this.getQuestionWithAnswerList();    
  }

  onActivate(event:any) {
    window.scroll(0,0);
  }

  getQuestionWithAnswerList() {
        this.ccdService.getQuestionWithAnswers(QuestionAnswersList.Skip).subscribe({
      next: (result: QuestionWithAnswerResponse[]) => {
        this.questionWithAnswerList = result;
                
      },
      error: error => {
        this.notificationService.sbOpenError('Error while getting data \n' + error.message);
        setTimeout(() => {
          
        }, this.globalConstants.setTimeOutAfterSubscription);
      }
    });
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
      this._destroying$.next(undefined);
      this._destroying$.complete();
  }
}




