import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuestionWithAnswerResponse } from '@app/model/questionWithAnswerResponse';
import { SelectionbyPathResponse } from '@app/model/selectionbyPathResponse';
import { Summary } from "@app/model/Summary";
import { ReferenceLink } from "@app/model/ReferenceLink";
import { Observable } from 'rxjs';
import { environment } from '@environments/environments'
import { protectedResources } from '@app/authentication/auth-config';
import { NextStepAdditionalInfo } from '@app/model/NextStepAdditionalInfo';
import  { GroupGuard} from '../authentication/group.guard'
import { QuestionAnswersList } from '@app/model/enumerations';

const baseUrl = `${environment.apiUrl}`;


@Injectable({
  providedIn: 'root'
})
export class CcdService {

  constructor(private _http: HttpClient , private  group:GroupGuard) { }  

  getQuestionWithAnswers(questionAnswerList: QuestionAnswersList): Observable<QuestionWithAnswerResponse[]> {

    if(questionAnswerList == QuestionAnswersList.All) {
      return this._http.get<QuestionWithAnswerResponse[]>(protectedResources.ccdAdminQuestionApi.endpoint);

    } else {

      return this._http.get<QuestionWithAnswerResponse[]>(protectedResources.ccdUserQuestionApi.endpoint);
    }
  }

  getAnswersByPath(path: string): Observable<SelectionbyPathResponse[]> {

    return this._http.get<SelectionbyPathResponse[]>(protectedResources.ccdSelectionPathApi.endpoint + path + '/answers');
  }

  getSoutionBySolutinId(id: number): Observable<Summary> {

    return this._http.get<Summary>(protectedResources.ccdSolutionApi.endpoint + id + '/relationshipItemsOnly');
  }


  getReferenceLinkBySolutinId(id: number): Observable<ReferenceLink[]> {

    return this._http.get<ReferenceLink[]>(protectedResources.ccdRefrencelinkApi.endpoint + id);
  }

  getNextStepBySolutinId(id: number): Observable<NextStepAdditionalInfo[]> {
    
    return this._http.get<NextStepAdditionalInfo[]>(protectedResources.ccdNextStepAdditionalInfoApi.endpoint + id);
  }

  addReferenceLink(data: ReferenceLink): Observable<any> {
    // return this._http.post(`${baseUrl}, referenceLinks`);
    return this._http.post(`${baseUrl}/referenceLinks`, data, { observe: 'response' });
  }

  updateReferenceLink(id: number, data: ReferenceLink): Observable<any> {
    return this._http.put(`${baseUrl}/referenceLinks/${id}`, data, { observe: 'response' });
  }


  deleteReferenceLink(id: number): Observable<any> {
    console.log('id=' + id)
    return this._http.delete(`${baseUrl}/referenceLinks?id=` + id);
  }  


  updateSolution(data: Summary): Observable<Summary> {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
    }
     return this._http.put<Summary>(protectedResources.ccdSolutionApiPut.endpoint + data.id +'/includeRelationship', data, httpOptions);
   
  }

}
