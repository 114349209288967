import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GroupGuard } from '../../../../authentication/group.guard';
import { GlobalConstants } from '@app/constants/global-constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() public sidenavToggle = new EventEmitter();
  @Output() public sidenavClose = new EventEmitter();
  subscriptions: Subscription[] = [];

  groupGaurd: GroupGuard;

  constructor(private router: Router,
    private elementRef: ElementRef, public dialog: MatDialog, private globalConstants: GlobalConstants
  ) { }

  ngOnInit(): void {
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  isAdmin() {
    return this.globalConstants.IsAdmin;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
