<div id="container">
    <div id="header">
        <app-header ></app-header>
    </div>
    <div id="body">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
    <div id="footer">
        <dmf-footer></dmf-footer>
    </div>
 </div>