import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Inject, Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Observable, of } from "rxjs";
import { concatMap } from "rxjs/operators";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-browser";
import { BaseGuard } from "./base.guard";
import { AppInsightsMonitoringService } from "@app/services/logging.service";
import { GlobalConstants } from "@app/constants/global-constants";

type AccountWithGroupClaims = AccountInfo & {
    idTokenClaims?: {
        groups?: string[],
        _claim_names?: {
            groups?: string | string[]
        },
        _claim_sources?: {
            src1?: {
                endpoint: string | string[]
            }
        }
    }
}

@Injectable()
export class GroupGuard extends BaseGuard {
    isAdmin: boolean | undefined = false;
    requiredGroupsByApplication: string[] = [];
    hasRequiredGroup: boolean | UrlTree;
    constructor(
        @Inject(MSAL_GUARD_CONFIG) protected override msalGuardConfig: MsalGuardConfiguration,
        protected override msalBroadcastService: MsalBroadcastService,
        protected override authService: MsalService,
        protected override location: Location,
        protected override router: Router,
        private logger: AppInsightsMonitoringService,
        private globalConstants: GlobalConstants
    ) {
        super(msalGuardConfig, msalBroadcastService, authService, location, router);
    }


    override activateHelper(state?: RouterStateSnapshot, route?: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        let result = super.activateHelper(state, route);

        var requiredGroups: string[] = route ? route.data['requiredGroups'].map((x: { value: any; }) => x.value) : [];

        var siteUrl = state?.url

        if (requiredGroups[0] == undefined && siteUrl == '/site/ccd-admin') {
            requiredGroups = ["admin"];
        } else {
            requiredGroups = ["user", "admin"];
        }       

        return result.pipe(
            concatMap(() => {
               
                let activeAccount = this.authService.instance.getActiveAccount() as AccountWithGroupClaims;

                if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
                    activeAccount = this.authService.instance.getAllAccounts()[0] as AccountWithGroupClaims;
                }

                // check either the ID token or a non-expired storage entry for the groups membership claim
                if (activeAccount?.idTokenClaims?.roles) {

                   // this.logger.logTrace("acctiveAccount before redirect to overage", [{ 'homeAccountId': activeAccount.homeAccountId }, { 'username': activeAccount.username }, { 'localAccountId': activeAccount.localAccountId }, { 'idToken': activeAccount.idToken }])


                    if (activeAccount?.idTokenClaims?.roles?.includes('admin') || activeAccount?.idTokenClaims?.roles?.includes('Admin')) {
                       
                        if (requiredGroups.includes('admin')) {
                            this.globalConstants.IsAdmin = true;
                            return of(true);
                        } else {
                            this.globalConstants.IsAdmin = false;
                            return of(false);

                        }
                    } else if (activeAccount?.idTokenClaims?.roles?.includes('user') || activeAccount?.idTokenClaims?.roles?.includes('User')) {

                        if (requiredGroups.includes('user')) {
                            this.globalConstants.IsAdmin = false;
                            return of(true);
                        } else {
                            this.globalConstants.IsAdmin = false;
                            return of(false);
                        }

                    } else if (!activeAccount?.idTokenClaims?.roles?.includes('user') || !activeAccount?.idTokenClaims?.roles?.includes('User') && !activeAccount?.idTokenClaims?.roles?.includes('admin') || !activeAccount?.idTokenClaims?.roles?.includes('Admin')) {
                        window.alert('Token does not have groups claim. Please ensure that your account is assigned to a security group and then sign-out and sign-in again.');
                        this.globalConstants.IsAdmin = false;
                        return of(false);
                    }
                }
               
                return of(false);
            })
        );
    }
}