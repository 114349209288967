import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ok-dlg-box',
  templateUrl: './ok-dlg-box.component.html',
  styleUrls: ['./ok-dlg-box.component.scss']
})
export class OkDlgBoxComponent implements OnInit {
  msgHeader: string;
  msgMain: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OkDlgBoxComponent>,

  ) { }

  ngOnInit(): void {
    this.msgHeader = this.data.message;
    this.msgMain = this.data.message2;

  }

  onNo(): void {
    this.dialogRef.close(false);
  }

  onClose(): void {
    this.dialogRef.close(true);
  }

}