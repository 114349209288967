import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from '@app/constants/global-constants';
import { QuestionWithAnswerResponse } from '@app/model/questionWithAnswerResponse';
import { SelectionbyPathResponse, DropDownClickAction } from '@app/model/selectionbyPathResponse';
import { AllSelectBoxesData as SelectBoxesData } from "@app/model/AllSelectBoxesData";
import { CcdService } from '@app/services/ccd.service';
import { NotificationService } from '@app/services/notification.service';
import { Solution } from '@app/model/Solution';
import { QuestionAnswersList } from '@app/model/enumerations';
import { AppInsightsMonitoringService } from '@app/services/logging.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ccd-ques-answer',
  templateUrl: './ccd-ques-answer.component.html',
  styleUrls: ['./ccd-ques-answer.component.scss']
})
export class CcdQuesAnswerComponent {

  public ccdForm: FormGroup;
  answerList: any = [];
  questionWithAnswerList: QuestionWithAnswerResponse[] = [];
  selectBoxDataList: SelectBoxesData[] = [];
  loading = false;
  completePath = '';
  subscriptions: Subscription[] = [];

  public adminEnum: QuestionAnswersList;

  @Output() solutionFound: EventEmitter<SelectionbyPathResponse[]> = new EventEmitter();
  @Output() dropdownclicked: EventEmitter<DropDownClickAction> = new EventEmitter();
  @Input() questionAnswerList: QuestionAnswersList;

  constructor(private formBuilder: FormBuilder
    , public zone: NgZone
    , private notificationService: NotificationService
    , private route: ActivatedRoute
    , private router: Router
    , private cd: ChangeDetectorRef
    , private ccdService: CcdService
    , private globalConstants: GlobalConstants
    , private logger: AppInsightsMonitoringService
  ) { }

  ngOnInit(): void {

    this.adminEnum = this.questionAnswerList;
    this.getQuestionWithAnswerList();
  }

  Reset() {
    this.globalConstants.selected=-1;
    for (let i = 0; i < this.selectBoxDataList.length; i++) {

      if (i == 0) {
        this.selectBoxDataList[i].selectedId = '';
        this.ccdForm.controls[i.toString()].setValue(null);
        this.selectBoxDataList[i].serviceDesciption = '';
        this.selectBoxDataList[i].path = '';
        this.completePath = '';
      } else {
        this.selectBoxDataList[i].selectedId = '';
        this.ccdForm.controls[i.toString()].setValue(null);
        this.selectBoxDataList[i].serviceDesciption = '';
        this.selectBoxDataList[i].path = '';
        this.ccdForm.controls[(i).toString()].disable();
        this.completePath = '';
      }
    }
    this.solutionFound.emit([]);
  }

  getQuestionWithAnswerList() {
    this.loading = true;
    var result= this.ccdService.getQuestionWithAnswers(this.questionAnswerList).subscribe({
      next: (result: QuestionWithAnswerResponse[]) => {

        this.questionWithAnswerList = result;
        this.createdFormControls();
      },
      error: error => {
        this.notificationService.sbOpenError('Error while getting data \n' + error.message);
        this.logger.logException(error);
        setTimeout(() => {
          this.loading = false;
        }, this.globalConstants.setTimeOutAfterSubscription);
      }
    });
    this.subscriptions.push(result);
  }


  GetSelectionWithPath(path: string, dropDownId: number) {
    this.loading = true;
    var result=this.ccdService.getAnswersByPath(path).subscribe({
      next: (result: SelectionbyPathResponse[]) => {

        if (this.selectBoxDataList[dropDownId]) {
          this.selectBoxDataList[dropDownId].dropDownbind = result;
        }
        if (result != undefined && result.length > 0) {

          var s = result.filter(x => x.solution?.id)


          if (s != undefined && s.length > 0) {

            this.solutionFound.emit(result);
          }
        }

      },
      error: error => {
        this.notificationService.sbOpenError('Error while getting data \n' + error.message);
        this.logger.logException(error);
        setTimeout(() => {
          this.loading = false;
        }, this.globalConstants.setTimeOutAfterSubscription);
      }
    });
    this.subscriptions.push(result);
  }

  createdFormControls() {

    this.ccdForm = this.formBuilder.group({})

    for (let i = 0; i < this.questionWithAnswerList.length; i++) {
      this.ccdForm.addControl(i.toString(), this.formBuilder.control('', []));
      let selectBoxData = new SelectBoxesData();
      selectBoxData.id = this.questionWithAnswerList[i].id;
      selectBoxData.label = this.questionWithAnswerList[i].name;
      selectBoxData.serviceDesciption = '';
      selectBoxData.indexNumber = i;
      selectBoxData.path = "";
      selectBoxData.selectedId = '  '
      selectBoxData.dropDownbind = [];
      if (i == 0) {
        selectBoxData.isDisabled = false;
        this.questionWithAnswerList[i].answers.forEach(data => {
          let ddlBound = new SelectionbyPathResponse();
          ddlBound.path = '';
          ddlBound.id = data.id;
          ddlBound.description = data.description;
          ddlBound.name = data.name;
          ddlBound.quesionId = selectBoxData.id;
          ddlBound.solution = new Solution()
          selectBoxData.dropDownbind.push(ddlBound);
        })

      } else {

        selectBoxData.isDisabled = true;
        this.ccdForm.controls[i.toString()].disable();
      }

      this.selectBoxDataList.push(selectBoxData);
    }
  }

  onAnswerChange(event: MatSelectChange, index: number) {

    this.globalConstants.selected = -1;
    const indexNumber: number = Number(event.source.id);
    const currentPath = this.selectBoxDataList[indexNumber].path;

    if (this.selectBoxDataList.length >= indexNumber) {

      let desc = this.selectBoxDataList[indexNumber]?.dropDownbind?.filter(x => x.id === event.value).map(ele => ele.description).toString();

      if (desc.length > 0 && index == 2) {
        this.selectBoxDataList[indexNumber].serviceDesciption = desc;
      }

    }

    let dropDownClickAction: DropDownClickAction = new DropDownClickAction();
    dropDownClickAction.optionId = Number(event.value);
    if (Number(event.source.id) == this.selectBoxDataList.length - 1) {
      dropDownClickAction.isLastIndex = true;
    } else {
      dropDownClickAction.isLastIndex = false;

      this.ccdForm.controls[(indexNumber + 1).toString()].enable();
      if (this.selectBoxDataList[indexNumber].path != '' && indexNumber > 0) {
        this.completePath = this.selectBoxDataList[indexNumber - 1].path;
        this.selectBoxDataList[indexNumber].path = '';

      }
    }
    this.dropdownclicked.emit(dropDownClickAction);

    for (let i = indexNumber + 1; i < this.selectBoxDataList.length; i++) {
      this.selectBoxDataList[i].dropDownbind = [];
      this.selectBoxDataList[i].selectedId = '';
      this.ccdForm.controls[i.toString()].setValue(null);
      this.selectBoxDataList[i].serviceDesciption = '';
      this.selectBoxDataList[i].path = '';
    }

    this.selectBoxDataList[indexNumber].selectedId = event.value;
    if (indexNumber == 0) {
      this.selectBoxDataList[indexNumber].path = event.value;
    } else {

      this.completePath = this.selectBoxDataList[indexNumber - 1].path; // reset path to previous value and append the change.
      this.selectBoxDataList[indexNumber].path = this.completePath + '|' + event.value;
      this.selectBoxDataList[indexNumber].isDisabled = false;
    }
    this.completePath = this.selectBoxDataList[indexNumber].path
    if (indexNumber + 1 <= this.selectBoxDataList.length) {

      this.GetSelectionWithPath(this.selectBoxDataList[indexNumber].path, indexNumber + 1)

    }
    for (let i = indexNumber + 1; i < this.selectBoxDataList.length - 1; i++) {
      this.ccdForm.controls[(i + 1).toString()].disable();

    }
  }
  ngOnDestroy() {

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();

    });
  }

}
