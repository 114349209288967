import { Solution } from "./Solution"
import { CrudAction } from "./enumerations"

export class SelectionbyPathResponse {
    id: number
    name: string
    description: string
    quesionId: number
    solution: Solution
    path: string
  }

  export class DropDownClickAction {
    isLastIndex: boolean
    optionId: Number
  }

  export class CrudData {
    action: CrudAction
    data: any
  }


