import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './site/core/navigation/header/header.component';
import { HomeComponent } from './site/core/navigation/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './shared/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './site/core/navigation/footer/footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes
} from '@azure/msal-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field'
import { msalConfig, loginRequest, protectedResources } from './authentication/auth-config'
import { CcdService } from './services/ccd.service';
import { BaseGuard } from './authentication/base.guard';
import { GroupGuard } from './authentication/group.guard';
import { CdkColumnDef, CdkNoDataRow, CdkRow } from '@angular/cdk/table';
import { AppInsightsMonitoringService } from './services/logging.service';


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
* MSAL Angular will automatically retrieve tokens for resources
*/
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.ccdUserQuestionApi.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.ccdUserQuestionApi.scopes.read]
    }
  ]);

  protectedResourceMap.set(protectedResources.ccdAdminQuestionApi.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.ccdAdminQuestionApi.scopes.read]
    }
  ]);

  protectedResourceMap.set(protectedResources.ccdSelectionPathApi.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.ccdSelectionPathApi.scopes.read]
    }
  ]);


  protectedResourceMap.set(protectedResources.ccdSolutionApi.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.ccdSolutionApi.scopes.read]
    }
  ]);


  protectedResourceMap.set(protectedResources.ccdSolutionApiPut.endpoint, [
    {
      httpMethod: 'PUT',
      scopes: [...protectedResources.ccdSolutionApiPut.scopes.read]
    }
  ]);

  protectedResourceMap.set(protectedResources.apiGraph.endpoint, [
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiGraph.scopes]
    }
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatToolbarModule,
    MatListModule,
    MatTableModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatIconModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    MsalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    BaseGuard,
    GroupGuard,
    MsalBroadcastService,
    CcdService,
    CdkNoDataRow,
    CdkRow,
    CdkColumnDef,
    AppInsightsMonitoringService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
