export const environment = {
  production: false,
  apiUrl: 'https://dev.ccd-api.ienergy.halliburton.com', //'https://dev.ccd-api.ienergy.halliburton.com' //'https://localhost:7211',
  showGenericErrMsg: true,
  auth: {
    clientId: 'c5c5934e-df3a-456a-ab92-e596e59f4cec', // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5', // Replace the placeholder with your tenant subdomain
    redirectUri: 'https://dev.customchemistry.ienergy.halliburton.com/', //'https://dev.customchemistry.ienergy.halliburton.com/',  // http://localhost:4200/' Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/', // Points to window.location.origin by default
  },
  scopes: {
    read: ["api://271bf587-1a4c-486f-8f3b-67c770929e88/ccd.read"]
  },
  appInsights: {
    instrumentationKey: 'b2a8a2f4-2971-43d9-b383-a1d00298abed'
  },
  groups: {
    groupAdmin: "0977cead-bf9e-4019-b9ae-2ca711092e76",
    groupMember: "e39dc61b-ff63-48b9-a78a-fd58479505ab"
  },

  secure: false
};