import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { GraphService } from '../services/graph.service';
import { setGroupsInStorage } from '../utils/storage-utils';
import { groups, protectedResources } from '../authentication/auth-config';
import { AppInsightsMonitoringService } from '@app/services/logging.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-overage',
    templateUrl: './overage.component.html',
    styleUrls: ['./overage.component.css']
})
export class OverageComponent implements OnInit {

    requiredGroupsByApplication: string[] = [];

    constructor(private authService: MsalService, private graphService: GraphService, private logger: AppInsightsMonitoringService) { }

    ngOnInit(): void {
        this.getGroups();
    }

    getGroups() {

       // this.logger.logTrace("getFilteredGroups call", groups);
        var grp = Object.values(groups);
        this.graphService.getFilteredGroups(grp).subscribe(response => {
            this.requiredGroupsByApplication = response.value;
        });
        //this.logger.logTrace("getFilteredGroups call with result", this.requiredGroupsByApplication);
    }
}