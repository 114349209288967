import { Injectable } from '@angular/core';

import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { Client, PageCollection } from '@microsoft/microsoft-graph-client';

import { protectedResources } from '../authentication/auth-config';
import { AppInsightsMonitoringService } from './logging.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GraphService {

    constructor(private authService: MsalService, private logger: AppInsightsMonitoringService, private _http: HttpClient) { }

    private getGraphClient(accessToken: string) {
        // Initialize Graph client
        const graphClient = Client.init({
            // Use the provided access token to authenticate requests
            authProvider: (done) => {
                done(null, accessToken);
            },
        });

        return graphClient;
    };

    private async getToken(scopes: string[]): Promise<string> {
        let authResponse: AuthenticationResult | null = null;

        try {

            authResponse = await this.authService.instance.acquireTokenPopup({
                scopes: protectedResources.apiGraph.scopes,
            });

            //this.logger.logTrace("get Token call in try block", [{ "account info": this.authService.instance.getActiveAccount()! }, { "scopes": scopes }]);

        } catch (error) {

          //  this.logger.logTrace("get Token call in catch block");
            if (error instanceof InteractionRequiredAuthError) {
                // TODO: get default interaction type from auth config

                authResponse = await this.authService.instance.acquireTokenPopup({
                    scopes: protectedResources.apiGraph.scopes,
                });
            }

            this.logger.logException(error);
        }

      //  this.logger.logTrace("authResponse.accessToken", [{ "authResponse.accessToken": authResponse ? authResponse.accessToken : "" }]);

        return authResponse ? authResponse.accessToken : "";
    }

    getFilteredGroups(filterGroups: string[] = []): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' })
        }

        return this._http.post(protectedResources.apiGraph.endpoint, { groupIds: filterGroups }, httpOptions);
    }    
}