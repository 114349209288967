<div layout="fxFlexFill">
  <mat-card layout="fxFlexFill" class="mat-card-width-100">

    <form *ngIf="selectBoxDataList.length > 0" [formGroup]="ccdForm" autocomplete="off" novalidate>
      <!-- Dynamic Dropdown boxes -->
      <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button color="warn" class="button-style btnSmall" (click)="Reset()"
          type="submit">Reset</button>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-card-content *ngIf="adminEnum==2 && completePath!=''">
          <mat-card-subtitle class="pathText"><b>Complete Path:&nbsp;{{completePath}}</b></mat-card-subtitle>
        </mat-card-content>
      </div>
      <div fxLayout="row" fxLayoutAlign="left left">

        <mat-card-content *ngIf="adminEnum==2">
          <mat-card-subtitle class="RedText"><b class="RedText">System Administrator</b></mat-card-subtitle>
        </mat-card-content>
      </div>
      <mat-card-content *ngFor="let x of selectBoxDataList; let i = index">

        <div fxLayout="row">
          <mat-label fxFlex="90" class="question" fxLayoutAlign="left"><b>{{x.label}}</b></mat-label>
          <mat-label fxFlex="10" *ngIf="adminEnum==2 && i==0 && completePath!=''"
            fxLayoutAlign="center center">ID</mat-label>
        </div>

        <div fxLayout="row">

          <mat-form-field fxFlex="90" appearance="outline" color="warn" [hideRequiredMarker]="true">

            <mat-select formControlName="{{x.indexNumber}}" id="{{x.indexNumber}}"
              (selectionChange)="onAnswerChange($event,x.indexNumber)">

              <mat-option *ngFor="let ph of x.dropDownbind" [value]="ph.id">
                {{ph.name}}
              </mat-option>

            </mat-select>

          </mat-form-field>
          <mat-label *ngIf="adminEnum==2" fxFlex="10" fxLayoutAlign="center center">{{x.selectedId}}</mat-label>
        </div>
        <div fxLayout="row" *ngIf="x.serviceDesciption.length !=0">
          <mat-label fxFlex="90" *ngIf="x.serviceDesciption.length !=0"
            fxLayoutAlign="left">{{x.serviceDesciption}}</mat-label>
        </div>
        <br *ngIf="x.serviceDesciption.length !=0">
      </mat-card-content>

    </form>

  </mat-card>

</div>