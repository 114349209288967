import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dlg-box',
  templateUrl: './confirm-dlg-box.component.html',
  styleUrls: ['./confirm-dlg-box.component.scss']
})
export class ConfirmDlgBoxComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public confirmMSg: string,
    public dialogRef: MatDialogRef<ConfirmDlgBoxComponent>
  ) { }

  ngOnInit(): void {
  }

  onNo(): void {
    this.dialogRef.close(false);
  }

  onYes(): void {
    this.dialogRef.close(true);
  }


}
