import { SelectionbyPathResponse } from "./selectionbyPathResponse";



export class AllSelectBoxesData {
  indexNumber: number;
  id: number;
  label: string;
  serviceDesciption: string;
  isDisabled: boolean;
  dropDownbind: SelectionbyPathResponse[];
  path: string;
  selectedId: string;
}
