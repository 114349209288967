<mat-card class='card-section'>
    <mat-card-title>You have too many group memberships!</mat-card-title>
    <mat-card-content>The application will now query Microsoft Graph to check if you are member of any of the required groups by the application.</mat-card-content>
    <mat-card-content>This operation requires <b>Admin Consent</b> for the <b>GroupMember.Read.All</b> scope</mat-card-content>
    <mat-card-content>Once this is done, you may go back and try to access again.</mat-card-content>
    <mat-card-actions>
        <button mat-raised-button [disabled]="requiredGroupsByApplication.length==0"  [routerLink]="['/site/ccd-user']">I understand, take me back</button>
    </mat-card-actions>
    <hr>
    <mat-card-subtitle>You are a member of the following groups:</mat-card-subtitle>
    <ol>
        <li *ngFor="let group of requiredGroupsByApplication">{{group}}</li>
    </ol>
</mat-card>