import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalConstants } from '@app/constants/global-constants';
import { NotificationService } from '@app/services/notification.service';
import { MatDialog } from "@angular/material/dialog"
import { ConfirmDlgBoxComponent } from "@shared/control/confirm-dlg-box/confirm-dlg-box.component"
import { OkDlgBoxComponent } from "@shared/control/ok-dlg-box/ok-dlg-box.component"
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading: boolean = false;
  subscriptions: Subscription[] = [];
  constructor(private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private router: Router,
    private globalConstants: GlobalConstants, public dialog: MatDialog,) { }

  ngOnInit(): void {
    if (this.router.url === '/' || this.router.url === 'home') {
      this.router.navigate(['/site/ccd-user']);
    }
  }

  SuccessMsg() {
    this.notificationService.sbOpenSuccess(this.globalConstants.waitSuccessMsg);
  }

  failMsg() {
    this.notificationService.sbOpenError(this.globalConstants.waitFailureMsg);
  }

  showConfirmDialog() {
    const dialogRef = this.dialog.open(ConfirmDlgBoxComponent, {
      width: '550px',
      data: "PDF export successful. Do you want to reset the page data?"
    });
    var setResolved$ = dialogRef.afterClosed().subscribe(result => {
    });
    this.subscriptions.push(setResolved$);
  }

  showOkdialog() {
    const dialogRef = this.dialog.open(OkDlgBoxComponent, {
      width: '500',
      data: { message: 'Add operation result', message2: 'Loading was successful. Please close this dialog.' },
      autoFocus: false
    });

  }

  showSpinner() {
    this.loading = !this.loading;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }


}
